import React from 'react';
import './arrow.scss';

const Arrow = (props) => {
    const { direction, onClick, active } = props;
    return (
        <button className={`arrow ${active !== undefined ? (active ?'':'faded') : ''}`} 
            onClick={(evt) => { active!==undefined ? (active && onClick(evt)) : onClick(evt); }}>
            <img src={`/svg/icon-arrow-${direction}.svg`} />
        </button>
    );
}

export default Arrow;