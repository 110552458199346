/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './back-to-top.scss';
import smoothscroll from 'smoothscroll-polyfill';

const BackToTop = (props) => {
    const { inverse } = props;
    smoothscroll.polyfill();
    return (
        <a className={`back-to-top ${inverse ? 'inverse':''} animate__animated`} onClick={ () => { 
            document.querySelector('.top').scrollIntoView({ 
                behavior: 'auto' 
            });
            props.onClick && props.onClick();
         } }>
            {(inverse == undefined || !inverse) && <img src="/svg/icon-arrow-up.svg"/> }
            {inverse && <img src="/svg/icon-arrow-up-white.svg"/> }
            <p>Back to top</p>
        </a>
    );
}

export default BackToTop;