import React from 'react';
import { assetFolder } from './config';

const FullImage = ({folder, image}) => {
  return (
    <div className="full-image">
      <img src={`${assetFolder}${folder}/${image}`} alt="" />
    </div>
  )
}

export default FullImage;