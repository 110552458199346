import React, { Component } from 'react';
import './bio.scss';
import axios from 'axios';
import BioHighlight from '../../components/bioHighlight';
import BackToTop from '../../components/backToTop';

class Bio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlights: []
        }
    }
    componentDidMount() {
        let self = this;

        this.props.onToggleDark(false);

        axios.get('/data/bio-highlights.json')
            .then((response) => {
                this.setState({
                    highlights: response.data
                });
                document.querySelector('.top').scrollIntoView();
            })
            .catch((error) => {
                console.log(error)
            })
            .then(() => {

            });
    }
    render() {
        const { highlights } = this.state;
        return (
            <div className="page-outer bg-white bio">
                <a className="top"></a>
                <div className="page">
                    <section className="autoheight">
                        <div className="container">
                            <h1 className="page-title">The story so far.</h1>
                        </div>
                    </section>
                    <section className="highlights">
                        {highlights.map((h, i) => <BioHighlight content={h} key={i}></BioHighlight>)}
                    </section>
                    <section className="download">
                        <a href="/pdf/MARCUS_REBESCHINI-2021-07-06.pdf" target="_blank">Download CV</a>
                    </section>
                    <BackToTop></BackToTop>
                </div>
            </div>
        );
    }
}

export default Bio;