import React from 'react';
import { assetFolder } from './config';

const CaptionPhoto = ({
  folder,
  caption,
  image
}) => {
  return (
    <div className="caption-photo">
      <div className="caption">
        {caption}
      </div>
      <img src={`${assetFolder}${folder}/${image}`} alt="" />
    </div>
  );
}

export default CaptionPhoto;