import React, { Component } from 'react';
import './header.scss';
import Menu from '../menu';

class Header extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
    }
    render() {
        const { theatre, inverse } = this.props;
        return (
            <header className={`${theatre ? 'theatre':''} ${inverse ? 'inverse':''}`}>
                <div className="inner">
                    <section>
                        <Menu inverse={inverse}></Menu>
                    </section>
                </div>
            </header>
        );
    }
}

export default Header;