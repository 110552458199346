import React, { Component, createRef } from 'react';
import './news.scss';
import Arrow from '../arrow';
import Card from '../card';
import axios from 'axios';
import Next from '../next';
import smoothscroll from 'smoothscroll-polyfill';

class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            news: [],
            left: false,
            right: true
        }

        this.pagingStart = 0;
        this.pageSize = 3;

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrev = this.onClickPrev.bind(this);

        this.carouselNavElem = React.createRef();
        this.carouselInner = React.createRef();
        this.leftArrow = React.createRef();
        this.rightArrow = React.createRef();

        this.touchActive = false;

        this.sectionElem = React.createRef();
        this.newsInnerScrollableElem = React.createRef();
        this.newsInnerScrollableContentElem = React.createRef();
        this.scrollY = 0;
    }
    componentDidMount() {
        smoothscroll.polyfill();

        const getValueFromPX = (v) => {
            let re = new RegExp(/-?\d+\.?\d+/g);
            let matches = v.match(re);
            if (matches == null)
                return 0;
            else
                return parseInt(v.match(re)[0]);
        }

        // update page size based on screen

        let self = this;
        axios.get('/data/news.json')
        .then(function (response) {
            // handle success
            self.setState((state) => {
                
                return {
                    ...state,
                    news: response.data
                }
            });

            // set up swipe
            self.carouselInner.current.ontouchstart = (evt) => {
                self.touchActive = true;    
                self.touchX = evt.touches[0].pageX;
            }
            self.carouselInner.current.ontouchmove = (evt) => {
                let deltaX = evt.touches[0].pageX - self.touchX;
                self.carouselInner.current.style.left += deltaX;
                console.log('touchmove', deltaX);
            }
            self.carouselInner.current.ontouchend = (evt) => {
                self.touchActive = false;
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
          // always executed
        });
        
        this.isScrolling = false;

        this.carouselInner.current.ontransitionend = () => {
            this.carouselInnerTransitioning = false;
        }

        const scrollVertical = (deltaY, callback) => {
            self.maxScroll = this.newsInnerScrollableContentElem.current.offsetHeight - this.newsInnerScrollableElem.current.offsetHeight;
            if (Math.floor(self.scrollY) == 0 && deltaY <= 0) {
                return true;
            } else
            if (Math.ceil(self.scrollY) == self.maxScroll && deltaY >= 0) {
                return true;
            } else {
                if ((self.maxScroll - Math.ceil(self.scrollY)) <= deltaY && deltaY > 0)
                    self.scrollY = self.maxScroll;
                else {
                    if (self.scrollY + deltaY < 0) 
                        self.scrollY = 0
                    else
                    self.scrollY += deltaY;
                }

                if (self.maxScroll - Math.floor(self.scrollY) <= 80) {
                    //console.log('scroll end');
                    self.props.onScrollEnd && self.props.onScrollEnd();
                } else {
                    self.props.onScrollNotEnd && self.props.onScrollNotEnd();
                }

                this.newsInnerScrollableContentElem.current.style.top = (-1 * self.scrollY) + 'px';
    
                callback && callback();
            }
        }
        
        this.sectionElem.current.onwheel = (evt) => {
            scrollVertical(evt.deltaY, () => {
                evt.stopPropagation();
            });
        }
        this.sectionElem.current.ontouchstart = (evt) => {
            self.touchLastY = evt.touches[0].pageY;
            self.touchStartYInitial = evt.touches[0].pageY;
        };
        this.sectionElem.current.ontouchmove = (evt) => {
            let deltaY = evt.changedTouches[0].pageY - self.touchLastY;
            self.touchLastY = evt.changedTouches[0].pageY;
            self.lastDeltaY = deltaY;
            scrollVertical(-1 * deltaY, () => {
                evt.stopPropagation();
            });
        };
        this.sectionElem.current.ontouchend = (evt) => {
            let deltaFromTouch = evt.changedTouches[0].pageY - this.touchStartYInitial;
            if (((-1 * deltaFromTouch > 0 && self.scrollY == self.maxScroll)
                || (-1 * deltaFromTouch < 0 && self.scrollY == 0))
                && Math.abs(deltaFromTouch) > 100) {
                return true;
            } else {
                let deltaY = self.lastDeltaY > 0 ? 1 : -1;
                scrollVertical(-1 * deltaY, () => {
                    evt.stopPropagation();
                });
            }
        };

        this.newsInnerScrollableElem.current.ontouchstart = (evt) => {
            this.touchLastX = evt.touches[0].pageX;
            this.carouselInnerX = this.carouselInner.current.offsetLeft;
        }
        this.newsInnerScrollableElem.current.ontouchmove = (evt) => {
            this.carouselInner.current.classList.add('swiping');
            let delta = evt.touches[0].pageX - self.touchLastX;
            // apply left position=
            let left = self.carouselInnerX + delta;

            let maxLeft = -1 * (self.carouselInner.current.offsetWidth - document.querySelector('.news .card').offsetWidth);
            if (left >= maxLeft && left <= 0) 
                self.carouselInner.current.style.left = left + 'px';
        }
        this.newsInnerScrollableElem.current.ontouchend = (evt) => {
            let deltaY = evt.changedTouches[0].pageY - self.touchLastY;
            let deltaX = evt.changedTouches[0].pageX - self.touchLastX; 
            if (Math.abs(deltaX) < 10) {
                return true;
            } else
            if (evt.changedTouches.length > 0) {
                let cardElement = document.querySelector('.news .card');
                this.carouselInner.current.classList.remove('swiping');
                let moveWidth = (cardElement.offsetWidth);
                let delta = evt.changedTouches[0].pageX - self.touchLastX;
                
                // snap in multiples of card with + margin left
                let left = getValueFromPX(self.carouselInner.current.style.left) + delta;
                let r = left % moveWidth;

                if (left < 0) {
                    let m = moveWidth/2 * -1;
                    if (r <= m) {
                        left = left - (moveWidth + r);
                    } else {
                        left = left - r;
                    }
                } else {
                    let m = moveWidth/2;
                    if (r >= m) {
                        left = left + (moveWidth - r);
                    } else {
                        left = left - r;
                    }
                }
                
                let maxLeft = -1 * (self.carouselInner.current.offsetWidth - document.querySelector('.news .card').offsetWidth);
                if (left >= maxLeft && left <= 0) 
                    self.carouselInner.current.style.left = left + 'px';
                    
                this.pagingStart = Math.ceil(Math.abs(left / document.querySelector('.news .carousel .card').offsetWidth));
            }
        }
            
    }
    componentDidUpdate(prevProps, prevState) {
        let self = this;
        const { active } = this.props;
        if (prevProps.active !== active) {
            if (active) {
                this.newsInnerScrollableContentElem.current.style.top = '0px';
                let maxScroll = self.newsInnerScrollableContentElem.current.offsetHeight - self.newsInnerScrollableElem.current.offsetHeight;
                if (maxScroll - Math.floor(self.scrollY) <= 80) {
                    //console.log('scroll end');
                    self.props.onScrollEnd && self.props.onScrollEnd();
                } else {
                    self.props.onScrollNotEnd && self.props.onScrollNotEnd();
                }
                
                let card = document.querySelector('.news .carousel .card');
                if (card) {
                    let cardWidth = card.offsetWidth;
                    this.pagingStart = 0;
                    this.carouselInner.current.style.left = (-1 * this.pagingStart * cardWidth) + 'px';
                    this.setState({
                        left: this.pagingStart > 0,
                        right: true
                    });
                }
            }
        }

        if (prevState.news.length == 0 && this.state.news.length > 0) {
            let card = document.querySelector('.news .card');
            if (card !== null)
                self.pageSize = Math.floor(window.innerWidth / card.offsetWidth);
        }
    }
    onClickNext() {
        if ((this.pagingStart + this.pageSize) < this.state.news.length && !this.carouselInnerTransitioning) {
            this.carouselInnerTransitioning = true;
            let cardWidth = document.querySelector('.news .carousel .card').offsetWidth;
            this.pagingStart = this.pagingStart + this.pageSize;
            this.carouselInner.current.style.left = (-1 * this.pagingStart * cardWidth) + 'px';
            this.setState({
                left: true,
                right: ((this.pagingStart + this.pageSize) <= (this.state.news.length - 1))
            });
        }
    }
    onClickPrev() {
        if ((this.pagingStart - this.pageSize) >= 0 && !this.carouselInnerTransitioning) {
            this.carouselInnerTransitioning = true;
            let cardWidth = document.querySelector('.news .carousel .card').offsetWidth;
            this.pagingStart = this.pagingStart - this.pageSize;
            this.carouselInner.current.style.left = (-1 * this.pagingStart * cardWidth) + 'px';
            this.setState({
                left: this.pagingStart > 0,
                right: true
            });
        }
    }
    render() {
        const { news, left, right } = this.state;
        return (
            <section className="news anchor-news" ref={this.sectionElem}>
                <div className="container">
                    <div className="news-inner">
                        <h1 className="page-title animate__animated">The news so far.</h1>
                        <div className="news-inner-scrollable scrollable-x" ref={this.newsInnerScrollableElem}>
                            <div className="news-inner-scrollable-content" ref={this.newsInnerScrollableContentElem}>
                                <p className="animate__animated">
                                    From winning The Singapore Navy from Saatchi & Saatchi after being AOR for 28 years to sharing the stage with our client collecting The 2017 Mobile Grand Prix. Here are some news samples.
                                </p>
                                <div className="carousel-navigator animate__animated" ref={this.carouselNavElem}>
                                    <Arrow direction="left"
                                        active={left}
                                        onClick={this.onClickPrev.bind(this)}></Arrow>
                                    <Arrow direction="right"
                                        active={right}
                                        onClick={this.onClickNext.bind(this)}></Arrow>
                                </div>
                                <div className="carousel">
                                    <div className="inner" 
                                        ref={this.carouselInner}>
                                        {news.map((n, i) => 
                                            <Card key={i} index={i} content={n}></Card> )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default News;