import React, { Component } from 'react';
import WorksVideoPlayer from '../../components/workVideoPlayer';
import WorksSoFar from '../../components/worksSoFar';
import Referees from '../../components/referees';
import Contact from '../../components/contact';
import News from '../../components/news';
import BackToTop from '../../components/backToTop';
import Next from '../../components/next';
import './home.scss';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import SectionScrollable from '../../components/sectionScrollable';

const mobileBreak = 768;

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            works: [],
            nextSectionIndex: 1,
            nextInverse: false,
            videoPlaying: false,
            currentVideo: null
        }

        this.isScrolling = false;
        this.pageOuterElem = React.createRef();
        this.pageElem = React.createRef();
        this.handleNext = this.handleNext.bind(this);
        this.handlePrev = this.handlePrev.bind(this);
        this.nextSectionContainer = React.createRef();
        this.checkHideShowNext = this.checkHideShowNext.bind(this);
        this.stopVideo = this.stopVideo.bind(this);

        this.routeSections = {};

        this.windowOnResize = this.windowOnResize.bind(this);
        this.windowOnMouseWheel = this.windowOnMouseWheel.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleWheelSwipe = this.handleWheelSwipe.bind(this);

        this.mouseWheelLastTS = null;
        this.mouseWheelStop = true;
    }
    windowOnResize() {
        let activeSection = document.querySelectorAll('.page>section')[this.state.nextSectionIndex - 1];
        if (activeSection) {
            this.pageElem.current.scroll({
                top: activeSection.offsetTop,
                behavior: 'smooth' 
            });
            this.checkHideShowNext(this.state.nextSectionIndex - 1);
        }
    }
    handleWheelSwipe(deltaY) {
        if (this.mouseWheelStop && (deltaY > 30 || deltaY < -30)) {
            this.mouseWheelStop = false;
            if (deltaY > 0) {
                this.handleNext();
            } else 
            if (deltaY < 0) {
                this.handlePrev();
            }
        }
    }
    windowOnMouseWheel(evt) {
        this.handleWheelSwipe(evt.deltaY);
    }
    componentDidMount() {
        let self = this;

        const { match } = self.props;

        document.querySelectorAll('.page>section')[0].classList.add('active');

        // load works.json
        axios.get('/data/works.json')
        .then(function (response) {
            // handle success
            self.routeSections = {
                "": 0,
                "home": 0,
                "results": 2 + response.data.length,
                "news": response.data.length + 3,
                "references": response.data.length + 5
            };

            self.setState({
                loaded: true,
                works: response.data,
                nextSectionIndex: self.routeSections[match.params[0]] + 1
            });

            //console.log(document.querySelector(`.anchor-${match.params[0]}`).offsetTop);

            self.pageElem.current.scroll({
                top: match.params[0] === '' ? 0 : document.querySelector(`.anchor-${match.params[0]}`).offsetTop,
                behavior: 'auto' 
            });
            
            self.checkHideShowNext(self.routeSections[match.params[0]]);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
          // always executed
        });

        /*
        // default first section to active class
        let firstSection = document.querySelectorAll('.page>section')[0];
        firstSection.classList.add('active');
        */

        // adjust the frames on window resize
        this.pageElem.current.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.windowOnResize);
        this.pageElem.current.addEventListener('mousewheel', this.windowOnMouseWheel);

        // event for swiping
        this.touchLastY = 0;
        this.pageElem.current.addEventListener('touchstart', (evt) => {
            self.touchLastY = evt.touches[0].pageY;
        });
        this.pageElem.current.addEventListener('touchend', (evt) => {
            let deltaY = evt.changedTouches[0].pageY - self.touchLastY;
            this.handleWheelSwipe(-1 * deltaY);
        });
    }
    componentWillUnmount() {
        this.pageElem.current.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.windowOnResize);
        this.pageElem.current.removeEventListener('mousewheel', this.windowOnMouseWheel);
    }
    componentDidUpdate(prevProps, prevState) {
        const { match } = this.props;
        const { params } = match;

        if (this.mouseWheelStop) {
            if (prevProps.location !== this.props.location && prevState.nextSectionIndex == this.state.nextSectionIndex) {
                let nextSectionIndex = 1;
                let currSectionIndex = this.routeSections[ params[0] ];
                if (params[0] !== '' && currSectionIndex !== undefined && params[0] !== 'home') {
                    nextSectionIndex = currSectionIndex + 1;
                    
                    this.pageElem.current.scroll({
                        top: document.querySelector(`.anchor-${params[0]}`).offsetTop,
                        behavior: "auto"
                    });
                    this.checkHideShowNext(currSectionIndex);

                } else {
                    this.checkHideShowNext(0);
                    this.pageElem.current.scroll({
                        top: 0,
                        behavior: "auto"
                    });
                    
                }

                let currSection = document.querySelectorAll('.page>section')[currSectionIndex];
                if (currSection)
                    currSection.classList.add('active');
    
                this.setState((state) => {
                    return {
                        ...state,
                        nextSectionIndex: nextSectionIndex
                    }
                });
            } 
            
        }

        if (prevState.nextSectionIndex !== this.state.nextSectionIndex) {
            this.checkHideShowNext(this.state.nextSectionIndex - 1);
        }
    }
    showNext() {
        this.nextSectionContainer.current.classList.remove('hide');
    }
    hideNext() {
        this.nextSectionContainer.current.classList.add('hide');
    }
    inverseNext() {
        this.setState({
            nextInverse: true
        });
    }
    removeInverseNext() {
        this.setState({
            nextInverse: false
        });
    }
    checkHideShowNext(currSectionIndex) {
        this.hideNext();
        clearTimeout(this.checkHideShowNextTimeout);
        this.checkHideShowNextTimeout = setTimeout(() => {
            let nextSection = document.querySelectorAll('.page>section')[currSectionIndex + 1];
            let currSection = document.querySelectorAll('.page>section')[currSectionIndex];
            let scrollable = currSection.querySelector('.scrollable');

            if (currSection.scrollHeight > window.innerHeight 
                || nextSection === undefined
                || (scrollable !== null && scrollable.scrollHeight > window.innerHeight)) {
                this.hideNext();
            } else
                this.showNext();
    
            if (currSection !== undefined) {
                if (currSection.classList.contains('dark')) {
                    this.inverseNext();
                    this.props.onToggleDark(true);
                } else {
                    this.removeInverseNext();
                    this.props.onToggleDark(false);
                }
            }
        }, 1000);
        
    }
    handleScroll() {
        clearTimeout(this.mouseWheelLastT);
        this.mouseWheelLastT = setTimeout(() => {
            this.mouseWheelLastTS = new Date().getTime();
            this.mouseWheelStop = true;
        }, 300);
    }
    handleNext() {
        if (this.pageLock) return false;

        let nextSection = document.querySelectorAll('.page>section')[this.state.nextSectionIndex];
       // forward
        if (nextSection) {
            
            // pause current video
            this.stopVideo();

            // add class for entrance and exit animations
            let currSection = document.querySelectorAll('.page>section')[this.state.nextSectionIndex - 1];
            if (currSection) {
                currSection.classList.remove('active');
                currSection.classList.remove('active-from-bottom');
            }
            
            nextSection.classList.add('active');

            let indexRoutes = {}
            for ( let r in this.routeSections) {
                indexRoutes[this.routeSections[r]] = r;
            }

            if (indexRoutes[this.state.nextSectionIndex] !== undefined) {
                this.props.history.replace({
                    pathname: '/' + indexRoutes[this.state.nextSectionIndex],
                    hash: '',
                    state: {
                        fromNext: true
                    }
                });
            } else {
                this.props.history.replace({
                    pathname: '/',
                    hash: '#' + this.state.nextSectionIndex,
                    state: {
                        fromNext: true
                    }
                });
            }
            
            this.pageElem.current.scroll({
                top: nextSection.offsetTop,
                behavior: 'smooth' 
            });

            this.pageLock = true;

            clearTimeout(this.framePauseTimeout);
            this.framePauseTimeout = setTimeout(() => {
                this.pageLock = false;
                this.mouseWheelStop = true;
            }, 1000);

            this.setState((state) => {
                return {
                    ...state,
                    nextSectionIndex: state.nextSectionIndex + 1
                }
            });
        } else {
            this.mouseWheelStop = true;
        }
    }
    handlePrev() {
        let prevSectionIndex = this.state.nextSectionIndex - 2;
        
        let prevSection = document.querySelectorAll('.page>section')[prevSectionIndex];
       // forward
        if (prevSection) {
            // pause current video
            this.stopVideo();

            // add class for entrance and exit animations
            let currSection = document.querySelectorAll('.page>section')[this.state.nextSectionIndex - 1];
            if (currSection) {
                currSection.classList.remove('active');
                currSection.classList.remove('active-from-bottom');
            }
            
            prevSection.classList.add('active');
            prevSection.classList.add('active-from-bottom');

            let indexRoutes = {}
            for ( let r in this.routeSections) {
                indexRoutes[this.routeSections[r]] = r;
            }

            if (indexRoutes[prevSectionIndex] !== undefined) {
                this.props.history.replace({
                    pathname: '/' + indexRoutes[prevSectionIndex],
                    hash: '',
                    state: {
                        fromNext: true
                    }
                });
            } else {
                this.props.history.replace({
                    pathname: '/',
                    hash: '#' + prevSectionIndex,
                    state: {
                        fromNext: true
                    }
                });
            }
            
            this.pageElem.current.scroll({
                top: prevSection.offsetTop,
                behavior: 'smooth' 
            });
            this.setState((state) => {
                return {
                    ...state,
                    nextSectionIndex: prevSectionIndex + 1
                }
            });
        
        } else {
            this.mouseWheelStop = true;
        }
    }
    stopVideo() {
        if (this.state.videoPlaying)
            this.state.currentVideo.pause();
    }
    handleOnVideoPlay({ video }) {
        this.setState({
            videoPlaying: true,
            currentVideo: video
        });
        this.nextSectionContainer.current.classList.add('hide');
        this.props.onTheatreToggle(true);
    }
    handleOnVideoEnd({ video, goNext }) {
        this.setState({
            videoPlaying: false,
            currentVideo: null
        });
        this.nextSectionContainer.current.classList.remove('hide');
        this.props.onTheatreToggle(false);

        if (goNext) {
            this.handleNext();
        }
    }
    render() {
        const { works, nextSectionIndex, nextInverse, videoPlaying } = this.state;
        return (
            <div className="page-outer bg-mustard home" ref={this.pageOuterElem}>
                <div className="page" ref={this.pageElem}>
                    <a className="top anchor-home"></a>
                    <SectionScrollable className="section-text text-1"
                        onScrollNotEnd={this.hideNext.bind(this)}
                        onScrollEnd={this.showNext.bind(this)}
                        hideNext={this.hideNext.bind(this)}
                        showNext={this.showNext.bind(this)}
                        active={(nextSectionIndex - 1) == 0}  >
                        <>
                            <h1 className="animate__animated">
                                Hi, I’m <span className="name-profile-picture">Marcus*</span>. I am an ex-World champion Dirt Boarder, I restore 1970’s classic cars in my downtime, and I have two amazing daughters which my wife and I still haven’t found the operating manual for. I have lived in Singapore for a total of 19 years. New York for 3 years and whatever is left, Australia owns.
                            </h1>
                            <p className="sub animate__animated">
                                *It’s actually Marcus Rebeschini, but that’s a tad hard to put in a headline. What’s even harder is pronouncing it. Mark-us - Reba-ski-knee.
                            </p>
                        </>
                    </SectionScrollable>
                    <SectionScrollable className="section-text text-2"
                        onScrollNotEnd={this.hideNext.bind(this)}
                        onScrollEnd={this.showNext.bind(this)}
                        hideNext={this.hideNext.bind(this)}
                        showNext={this.showNext.bind(this)}
                        active={(nextSectionIndex - 1) == 1} >
                        <>
                            <div className="row">
                                <div className="col">
                                    <h4 className="animate__animated">
                                        “A firm believer of the philosophy of; you’re only as good as the last thing you did.”
                                    </h4>
                                </div>
                                <div className="col">
                                    <p className="animate__animated">
                                        So I’ve included a 2-episode special on National Geographic channel supported by Caltex as well as five other samples of work. 
                                    </p>
                                    <p className="animate__animated">
                                        Furthermore, to the last thing I did, I have 20+ years of marketing experience working with local and global clients across Asia-Pacific and New York. I am recognized for innovative life-changing solutions that connect and engage consumers like myself.
                                    </p>
                                    <p className="animate__animated">
                                        But I should let the work speak for itself.
                                    </p>
                                </div>
                            </div>
                        </>
                    </SectionScrollable>
                    {works.map((w, i) =>
                        <WorksVideoPlayer key={i} 
                            nextAnchor={(i + 1) < works.length ? `section-${i + 2}`: 'news'} 
                            anchorClassName={`section-${i + 1}`}
                            active={(nextSectionIndex - 1) == (2 + i)}
                            onVideoPlay={this.handleOnVideoPlay.bind(this)}
                            onVideoEnd={this.handleOnVideoEnd.bind(this)}
                            content={w}>
                        </WorksVideoPlayer>
                    )}
                    <WorksSoFar 
                        active={(nextSectionIndex - 1) == (1 + works.length)}
                        onScrollNotEnd={this.hideNext.bind(this)}
                        onScrollEnd={this.showNext.bind(this)}></WorksSoFar>
                    <News
                        active={(nextSectionIndex - 1) == (2 + works.length)}
                        onScrollNotEnd={this.hideNext.bind(this)}
                        onScrollEnd={this.showNext.bind(this)}></News>
                    <SectionScrollable className="the-story-so-far"
                        onScrollNotEnd={this.hideNext.bind(this)}
                        onScrollEnd={this.showNext.bind(this)}
                        hideNext={this.hideNext.bind(this)}
                        showNext={this.showNext.bind(this)}
                        active={(nextSectionIndex - 1) == 1} >
                        <>
                            <h1 className="page-title animate__animated">The story so far.</h1>
                            <h3 className="animate__animated">
                                You've seen the work; it's results and the news that followed. Please get to know a little more about my work history here with my <NavLink to="/bio" className="bold">bio</NavLink>.
                            </h3>
                        </>
                    </SectionScrollable>
                    <SectionScrollable className="referees anchor-references"
                        onScrollNotEnd={this.hideNext.bind(this)}
                        onScrollEnd={this.showNext.bind(this)}
                        hideNext={this.hideNext.bind(this)}
                        showNext={this.showNext.bind(this)}
                        active={(nextSectionIndex - 1) == (3 + works.length)} >
                            <Referees></Referees>
                    </SectionScrollable>
                    <SectionScrollable className="contact"
                        onScrollNotEnd={this.hideNext.bind(this)}
                        hideNext={this.hideNext.bind(this)}
                        showNext={this.showNext.bind(this)}
                        active={(nextSectionIndex - 1) == (4 + works.length)} >
                        <div className="contact-inner">
                            <Contact></Contact>
                            <div className="back-to-top-row">
                                <BackToTop onClick={() => {
                                    this.showNext();
                                    this.props.history.push('/');
                                    this.setState({
                                        nextSectionIndex: 1
                                    });
                                }}></BackToTop>
                            </div>
                        </div>
                    </SectionScrollable>
                </div>
                <div className={`float-bt-center`} ref={this.nextSectionContainer}>
                    <Next
                        inverse={nextInverse}
                        onClick={this.handleNext.bind(this)}></Next>
                </div>
            </div>
        );    
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));