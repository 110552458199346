import React, { useEffect } from 'react';
import { assetFolder } from './config';

const VideoInsert = ({
  folder,
  image,
  video
}) => {

  useEffect(() => {

    return () => {
    }
  }, [])

  return (
    <div className="video-insert">
      <div className="video">
        <video controls>
          <source src={`${assetFolder}${folder}/${video}`} />
        </video>
      </div>
      <img src={`${assetFolder}${folder}/${image}`} alt="" />
    </div>
  );
}

export default VideoInsert;