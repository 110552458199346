import React from 'react';
import { assetFolder } from './config';

const PhotoRow = ({folder, images}) => {
  const division = 100 / images.length;
  return <div className="photo-row">
    {images.map((image) => (
      <div className="item" style={{flex: `1 1 ${division}%`}} >
        <img src={`${assetFolder}${folder}/${image}`} alt="" />
      </div>
    ))}
  </div>
}

export default PhotoRow;