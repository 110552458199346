import React, { Component } from 'react';
import './works-grid.scss';
import axios from 'axios';
import { NavLink  } from 'react-router-dom';

class WorksGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            works: []
        }
    }
    componentDidMount() {
        axios.get('/data/works-so-far.json')
            .then((response) => {
                let url = window.location.hash.split('#')[1];
                let filtered = response.data.filter((d) => {
                    return d.url !== url
                });
                this.setState({
                    works: filtered
                });
            })
            .catch((error) => {

            })
            .then(() => {

            })
    }
    render() {
        const { works } = this.state;
        return (
            <section className="works-grid">
                <div className="container">
                    <div className="grid">
                    {works.map((work, i) => (
                        <NavLink className="item" to={work.url} key={i}>
                            <div className="item-inner">
                                <img src={work.img} alt={work.img} />
                            </div>  
                        </NavLink>
                    ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default WorksGrid;