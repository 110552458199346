import React from 'react';
import './parallex-gallery.scss';
import { useParams } from 'react-router-dom';
import FullImage from './full-image';
import CaptionPhoto from './caption-photo';
import PhotoRow from './photo-row';
import PhotosWrap from './photos-wrap';
import VideoInsert from './video-insert';

const GALLERY_TYPES = {
  FULL_IMAGE: "FULL_IMAGE",
  CAPTION_TOP_LEFT: "CAPTION_TOP_LEFT",
  PHOTOS_ROW: "PHOTOS_ROW",
  PHOTOS_WRAP: "PHOTOS_WRAP",
  VIDEO_INSERT: "VIDEO_INSERT"
};

const data = {
  "caltex": [
    {
      type: GALLERY_TYPES.FULL_IMAGE,
      image: "1.webp",
    },
    {
      type: GALLERY_TYPES.CAPTION_TOP_LEFT,
      caption: "It's easy to do amazing things with a super car, so instead we broke records with your mom's 28-year old sedan.",
      image: "2.webp"
    },
    {
      type: GALLERY_TYPES.PHOTOS_ROW,
      images: [
        "2-1.webp",
        "2-2.webp",
        "2-3.webp",
      ]
    },
    {
      type: GALLERY_TYPES.FULL_IMAGE,
      image: "3.webp",
    },
    {
      type: GALLERY_TYPES.PHOTOS_ROW,
      images: [
        "3-1.gif",
        "3-2.gif",
      ]
    },
    {
      type: GALLERY_TYPES.FULL_IMAGE,
      image: "4.webp",
    },
    {
      type: GALLERY_TYPES.PHOTOS_WRAP,
      images: [
        [
          "4-1.webp",
          "4-2.webp"
        ],
        "4-3.webp",
        "4-4.webp",
      ]
    },
    {
      type: GALLERY_TYPES.FULL_IMAGE,
      image: "5.webp",
    },
    {
      type: GALLERY_TYPES.VIDEO_INSERT,
      image: "6.webp",
      video: "video-720p.mp4"
    },
    {
      type: GALLERY_TYPES.FULL_IMAGE,
      image: "7.webp",
    },
  ]
}

const ParallexGallery = () => {
  const { id } = useParams()

  const gallery = data[id];

  return (
    <div className="parallex-gallery">
      {gallery.map((item) => (
        <>
          {item.type === GALLERY_TYPES.FULL_IMAGE && 
            <FullImage folder={id} image={item.image}/>}
          {item.type === GALLERY_TYPES.CAPTION_TOP_LEFT && 
            <CaptionPhoto folder={id} caption={item.caption} image={item.image}/>}
          {item.type === GALLERY_TYPES.PHOTOS_ROW && 
            <PhotoRow folder={id} images={item.images}/>}
          {item.type === GALLERY_TYPES.PHOTOS_WRAP &&
            <PhotosWrap folder={id} images={item.images}/>}
          {item.type === GALLERY_TYPES.VIDEO_INSERT &&
            <VideoInsert folder={id} image={item.image} video={item.video} />}
        </>
      ))}
    </div>
  )
}

export default ParallexGallery;