import React, { Component } from 'react';
import './referees.scss';
import axios from 'axios';
import RefereePerson from '../refereePerson';
import Next from '../next';

class Referees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referees: []
        }
    }
    componentDidMount() {
        let self = this;
        axios.get('/data/referees.json')
        .then(function (response) {
            // handle success
            self.setState((state) => {
                return {
                    ...state,
                    referees: response.data
                }
            });
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
    render() {
        const { referees } = this.state;
        return (
            <div className="referees">
                <h1 className="page-title animate__animated">Get in touch with referees.</h1>
                <div className="row">
                {
                    referees.map((r, i) =>
                        <RefereePerson content={r} index={i} key={i}></RefereePerson>
                    )
                }
                </div>
            </div>
        );
    }
}

export default Referees;