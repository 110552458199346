import React, { Component } from 'react';
import { NavLink  } from 'react-router-dom';
import './menu.scss';

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            currentSection: 0
        };
    }
    onMenuClick = (evt) => {
        this.setState((state) => {
            return {
                open: !state.open
            }
        });
    }
    componentDidMount() {
        //
    }
    render() {
        const { open } = this.state;
        const { inverse } = this.props;
        return (
            <div className={`menu ${open ? 'opened':''} ${inverse ? 'inverse':''}`}>
                <div className="menu-icon" onClick={this.onMenuClick.bind(this)}>
                    <p className="closed">MENU</p>
                    <p className="opened">CLOSE</p>
                </div>
                <div className="menu-items">
                    <ul>
                        <li className="animate__animated" style={{"--index":0}}>
                            <NavLink className="large-black-headline" 
                                activeClassName="active" exact
                                onClick={this.onMenuClick.bind(this)} to="/">home</NavLink>
                        </li>
                        <li className="animate__animated" style={{"--index":1}}>
                            <NavLink className="large-black-headline"
                                activeClassName="active"
                                onClick={this.onMenuClick.bind(this)} to="/results">results</NavLink>
                        </li>
                        <li className="animate__animated" style={{"--index":3}}>
                            <NavLink className="large-black-headline" 
                                activeClassName="active"
                                onClick={this.onMenuClick.bind(this)} to="/news">news</NavLink>
                        </li>
                        <li className="animate__animated" style={{"--index":4}}>
                            <NavLink className="large-black-headline" 
                                activeClassName="active"
                                onClick={this.onMenuClick.bind(this)} to="/bio">bio</NavLink>
                        </li>
                        <li className="animate__animated" style={{"--index":5}}>
                            <NavLink className="large-black-headline"  
                                activeClassName="active"
                                onClick={this.onMenuClick.bind(this)} to="/references">references</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="menu-bg"></div>
            </div>
        );
    }
}

export default Menu;