import React, { Component } from 'react';
import './work-video-player.scss';
import Next from '../next';
import '../../components/loader';
import Loader from '../../components/loader';

class WorkVideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.stopVideo = this.stopVideo.bind(this);
        this.playVideo = this.playVideo.bind(this);
        this.videoPreviewElem = React.createRef();
        this.videoElem = React.createRef();
        this.videoDiv = React.createRef();
        this.imgContainerElem = React.createRef();
        this.windowBlur = false;
        this.state = {
            videoLoading: false,
            videoPlaying: false,
            videoStarted: false
        };
        this.pauseByUser = false;
    }
    componentDidUpdate(prevProps) {
        const { active } = this.props;
        if (prevProps.active != active) {
            if (active) {
                //console.log('video works is active');
                this.previewVideoPlayTimeout = setTimeout(() => {
                    this.videoPreviewElem.current.classList.add('visible');
                    this.videoPreviewElem.current.play();
                }, 500);
                /*
                this.imgContainerElem.current.onanimationend = () => {
                    this.videoPreviewElem.current.ontransitionend = () => {
                        this.videoPreviewElem.current.play();
                    }
                    this.videoPreviewElem.current.classList.add('visible');
                }
                */
            } else {
                this.videoPreviewElem.current.pause();
                clearTimeout(this.previewVideoPlayTimeout);
            }
        }
    }
    pausePlayVideo() {
        this.setState((state) => {
            if (state.videoPlaying) {
                this.videoElem.current.pause();
                this.pauseByUser = true;
            } else {
                this.videoElem.current.play();
                this.pauseByUser = false;
            }
            return {
                ...state,
                videoPlaying: !state.videoPlaying
            }
        });
    }
    stopVideo() {
        let self = this;

        const { onVideoEnd } = this.props;

        this.videoElem.current.pause();
        this.videoDiv.current.classList.remove('active');
        this.imgContainerElem.current.classList.remove('dim');
        onVideoEnd && onVideoEnd({
            video: self.videoElem.current
        });
        this.setState((state) => {
            return {
                videoPlaying: false,
                videoStarted: false
            }
        });
    }
    playVideo() {
        let self = this;
        const { onVideoPlay, onVideoEnd } = this.props;
        const { video } = this.props.content;
        this.videoElem.current.src = video;
        
        this.setState({
            videoLoading: true
        });

        this.videoElem.current.oncanplaythrough = (event) => {
            this.imgContainerElem.current.classList.add('dim');
            this.setState({
                videoLoading: false,
                videoStarted: true,
                videoPlaying: true
            });
            this.videoElem.current.play();
            onVideoPlay && onVideoPlay({
                video: this.videoElem.current
            });
        }
        const pauseStopVideo = (evt) => {
            if (!self.windowBlur && !this.pauseByUser) {
                self.videoDiv.current.classList.remove('active');
                this.imgContainerElem.current.classList.remove('dim');
                onVideoEnd && onVideoEnd({
                    video: self.videoElem.current
                });
                this.setState({
                    videoPlaying: false
                });
            }
        }
        const endedVideo = (evt) => {
            this.imgContainerElem.current.classList.remove('dim');
            onVideoEnd && onVideoEnd({
                video: self.videoElem.current,
                goNext: true
            });
            this.setState({
                videoPlaying: false,
                videoStarted: false
            });
        }
        this.videoElem.current.onended = endedVideo;
        this.videoElem.current.onpause = pauseStopVideo;
        
        this.videoElem.current.load();
        this.videoDiv.current.classList.add('active');

        // pause the video
        window.onblur = () => {
            if (this.props.active && this.state.videoStarted) {
                if (!this.pauseByUser) {
                    this.windowBlur = true;
                    this.pauseByUser = false;
                    this.videoElem.current.pause();
                    this.setState({
                        videoPlaying: false
                    });
                }
            }
        }
        // if paused by user, stay paused
        window.onfocus = () => {
            if (this.props.active && this.state.videoStarted) {
                this.windowBlur = false;

                if (!this.pauseByUser) {
                    if (this.videoElem.current.currentTime > 0)
                    this.videoElem.current.play();
                        
                    this.setState({
                        videoPlaying: true
                    });
                }
            }
        }
    }
    render() {
        const { active } = this.props;
        const { logo, img, title, preview, cta, titleStyle, captionStyle } = this.props.content;
        const { videoLoading, videoPlaying, videoStarted } = this.state;

        const ctaText = (cta !== undefined) ? cta : 'PLAY VIDEO';
        return (
            <section className={`work-video-player dark ${active ? 'active':''} ${videoLoading ? 'video-loading':''} ${videoStarted? 'video-started':''}`}>
                <Loader active={videoLoading}></Loader>
                <div className="captions" style={captionStyle}>
                    <img src={logo} className="animate__animated"/>
                    <p className="video-title animate__animated" dangerouslySetInnerHTML={{__html:title}} style={titleStyle}></p>
                    <button className="btn-play-video animate__animated" onClick={this.playVideo}>{ctaText}</button>
                </div>
                <div className="img-container animate__animated" 
                    style={{backgroundImage: `url(${img})`}} 
                    ref={this.imgContainerElem}>
                    {preview != '' && 
                    <video loop ref={this.videoPreviewElem}>
                        <source src={preview} type="video/mp4" />
                    </video> 
                    }
                </div>
                <div className="video" ref={this.videoDiv}>
                    <button className="btn-close-video" onClick={this.stopVideo.bind(this)}></button>
                    <div className={`video-control ${videoPlaying ? '':'paused'}`}>
                        <button className="btn-pause-play" onClick={this.pausePlayVideo.bind(this)}>
                            <span className="icon-pause"></span>
                            <span className="icon-play"></span>
                        </button>
                    </div>
                    <video ref={this.videoElem}>
                        <source src="" type="video/mp4" />
                    </video>
                </div>
            </section>
        );
    }
}

export default WorkVideoPlayer;