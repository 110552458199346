import React, { Component } from 'react';
import './next.scss';
import smoothscroll from 'smoothscroll-polyfill';

class Next extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    componentDidMount() {
        smoothscroll.polyfill();
    }
    onClick = () => {
        /*
        const { nextAnchor, querySelectorAll, index, onClicked } = this.props;
        if (querySelectorAll !== undefined && index !== undefined) {
            if (document.querySelectorAll(querySelectorAll)[index]) {
                document.querySelectorAll(querySelectorAll)[index].scrollIntoView({ 
                    behavior: 'smooth' 
                });
                onClicked();
            }
        } else
        if (document.querySelector(nextAnchor) != null) {
            document.querySelector(nextAnchor).scrollIntoView({ 
                behavior: 'smooth' 
            });
            onClicked();
        }
        */
       this.props.onClick();
    }
    render() {
        const { className, inverse } = this.props;
        return (
            <button className={`next ${className !== undefined ? className:'' }`} onClick={this.onClick}>
                {inverse && <img className="inverse-icon" src="/svg/icon-arrow-down-white.svg" />}
                {!inverse && <img src="/svg/icon-arrow-down.svg" />}
            </button>
        );
    }
}

export default Next;