import React, { Component } from 'react';
import './works-so-far.scss';
import Card from '../card';
import axios from 'axios';
import Arrow from '../arrow';
import smoothscroll from 'smoothscroll-polyfill';
import { compose } from 'redux';

class WorksSoFar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            works: [],
            left: false,
            right: true
        }
        
        this.addPx = this.addPx.bind(this);
        this.getValueFromPX = this.getValueFromPX.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrev = this.onClickPrev.bind(this);
        this.leftArrow = React.createRef();
        this.rightArrow = React.createRef();
        
        this.cardsContainerWrapper = React.createRef();
        this.cardsContainer = React.createRef();
        this.checkCardsPositionShowHide = this.checkCardsPositionShowHide.bind(this);
        this.sectionElem = React.createRef();
        this.scrollY = 0;
    }
    componentDidMount() {
        smoothscroll.polyfill();

        let self = this;
        axios.get('/data/works-so-far.json')
        .then(function (response) {
            // handle success
            self.setState((state) => {
                return {
                    ...state,
                    works: response.data
                }
            });

            let maxScroll = self.cardsContainer.current.offsetHeight;
            if (maxScroll - Math.floor(self.cardsContainer.current.offsetTop) <= 80) {
                //console.log('scroll end');
                self.props.onScrollEnd && self.props.onScrollEnd();
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
          // always executed
        });

        this.isScrolling = false;
        
        this.cardsContainer.current.ontransitionend = () => {
            this.isScrolling = false;
            this.cardContainerTransitioning = false;
            console.log('cards container transition end')
        }

        const scrollVertical = (deltaY, callback) => {

            self.isScrolling = true;
            self.maxScroll = self.cardsContainer.current.offsetHeight - self.cardsContainerWrapper.current.offsetHeight;
            
            if (Math.floor(self.scrollY) == 0 && deltaY < 0) {
                return true;
            } else
            if (Math.abs(Math.ceil(self.scrollY)) == self.maxScroll && deltaY > 0) {
                return true;
            } else {
                if ((Math.abs(self.maxScroll - Math.ceil(self.scrollY))) <= deltaY && deltaY > 0)
                    self.scrollY = self.maxScroll;
                else {
                    if (self.scrollY + deltaY < 0) 
                        self.scrollY = 0
                    else
                        self.scrollY += deltaY;
                }

                self.cardsContainer.current.style.top = (-1 * self.scrollY) + 'px';
                
                if (Math.abs(self.maxScroll - Math.ceil(self.scrollY)) <= 80) {
                    self.props.onScrollEnd && self.props.onScrollEnd();
                } else
                if (Math.abs(self.maxScroll - Math.ceil(self.scrollY)) > 80) {
                    self.props.onScrollNotEnd && self.props.onScrollNotEnd();
                }

                callback && callback();
            }
        }

        this.sectionElem.current.onwheel = (evt) => {
            scrollVertical(evt.deltaY, () => {
                evt.stopPropagation();
            })
        };
        this.sectionElem.current.ontouchstart = (evt) => {
            self.touchLastY = evt.touches[0].pageY;
            self.cardsContainerX = this.cardsContainer.current.offsetLeft;
            self.touchStartYInitial = evt.touches[0].pageY;
        };
        this.sectionElem.current.ontouchmove = (evt) => {
            let deltaY = evt.changedTouches[0].pageY - self.touchLastY;
            self.touchLastY = evt.changedTouches[0].pageY;
            scrollVertical(-1 * deltaY, () => {
                evt.stopPropagation();
            });
        };
        this.sectionElem.current.ontouchend = (evt) => {
            let deltaY = evt.changedTouches[0].pageY - self.touchLastY;
            let deltaFromTouch = evt.changedTouches[0].pageY - self.touchStartYInitial;
            
            if (((-1 * deltaFromTouch > 0 && self.scrollY == self.maxScroll)
                || (-1 * deltaFromTouch < 0 && self.scrollY == 0))
                && Math.abs(deltaFromTouch) >= 100) {
                return true;
            } else
                scrollVertical(-1 * deltaY, () => {
                    evt.stopPropagation();
                });
        };

        this.cardsContainer.current.style.left = '0px'; 
        this.cardsContainer.current.style.top = '0px';

        // bind swipe
        this.cardsContainer.current.ontouchstart = (evt) => {
            self.touchLastX = evt.touches[0].pageX;
            
        }
        this.cardsContainer.current.ontouchmove = (evt) => {
            // do not move if max
            let delta = evt.touches[0].pageX - self.touchLastX;   
            
            // apply left position=
            let left = self.cardsContainerX + delta;
            
            let maxLeft = -1 * (this.cardsContainer.current.offsetWidth - document.querySelector('.cards-container .card').offsetWidth - 7);
            if (left >= maxLeft && left <= 0) {    
                this.cardsContainer.current.classList.add('swiping');
                self.cardsContainer.current.style.left = left + 'px';
            }
        }
        this.cardsContainer.current.ontouchend = (evt) => {
            let deltaY = evt.changedTouches[0].pageY - self.touchLastY;
            let deltaX = evt.changedTouches[0].pageX - self.touchLastX; 
            if (Math.abs(deltaX) < 10) {
                return true;
            } else
            if (evt.changedTouches.length > 0) {
                this.cardsContainer.current.classList.remove('swiping');
                let cardElement = document.querySelector('.cards-container .card');
                let moveWidth = (cardElement.offsetWidth + 7);
                let delta = evt.changedTouches[0].pageX - self.touchLastX;
                
                // snap in multiples of card with + margin left
                let left = this.getValueFromPX(self.cardsContainer.current.style.left) + delta;
                let r = left % moveWidth;

                if (left < 0) {
                    let m = moveWidth/2 * -1;
                    if (r <= m) {
                        left = left - (moveWidth + r);
                    } else {
                        left = left - r;
                    }
                } else {
                    let m = moveWidth/2;
                    if (r >= m) {
                        left = left + (moveWidth - r);
                    } else {
                        left = left - r;
                    }
                }
                
                let maxLeft = -1 * (this.cardsContainer.current.offsetWidth - moveWidth);
                if (left >= maxLeft && left <= 0) {    
                    self.cardsContainer.current.style.left = left + 'px';
                }

                if (left == maxLeft)
                    this.setState({
                        left: true,
                        right: false
                    });
                evt.stopPropagation();
            }
        }
        
    }
    checkCardsPositionShowHide() {
        let cards = document.querySelectorAll('.works-so-far .card');
        let upper = this.scrollY;
        let lower = upper + this.cardsContainerWrapper.current.offsetHeight;
        for(let c of cards) {
            let cOffsetBottom = c.offsetTop + c.offsetHeight;
            if ((c.offsetTop >= upper && c.offsetTop <= lower) 
                || (cOffsetBottom >= upper && cOffsetBottom <= lower)) {
                c.classList.add('appear');
            } else {
                c.classList.remove('appear');
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { active } = this.props;
        if (prevProps.active !== active) {
            if (active) {
                
                this.isScrolling = false;
                this.cardContainerTransitioning = false;
                
                this.cardsContainerWrapper.current.scroll({
                    top: 0
                });

                this.scrollY = 0;
                
                this.cardsContainer.current.style.top = '0px';
                this.cardsContainer.current.style.left = '0px';
                let maxScroll = this.cardsContainer.current.offsetHeight - this.cardsContainerWrapper.current.offsetHeight;
                if (maxScroll + Math.floor(this.cardsContainer.current.offsetTop) <= 80) {
                    //console.log('scroll end');
                    this.props.onScrollEnd && this.props.onScrollEnd();
                }
            }
        }
    }
    addPx(v, add) {
        let re = new RegExp(/-?\d+\.?\d+/g);
        let v_num = v.match(re)[0];
        return (v_num + add) + 'px';
    }
    getValueFromPX(v) {
        let re = new RegExp(/-?\d+\.?\d+/g);
        let matches = v.match(re);
        if (matches == null)
            return 0;
        else
            return parseInt(v.match(re)[0]);
    }
    onClickPrev(evt) {
        if (!this.cardContainerTransitioning) {
            this.cardContainerTransitioning = true;

            let cardElement = document.querySelector('.cards-container .card');
            let moveWidth = (cardElement.offsetWidth + 7);
            if (this.cardsContainer.current.offsetLeft < 0) {
                let left = (this.cardsContainer.current.offsetLeft + moveWidth);
                left = left > 0 ? 0 : left;
                this.cardsContainer.current.style.left = left + 'px';
                this.setState({
                    right: true,
                    left: left < 0
                });
            }
        }
            
        evt.stopPropagation();
    }
    onClickNext(evt) {
        if (!this.cardContainerTransitioning) {
            this.cardContainerTransitioning = true;

            // 1 item at a time
            let cardElement = document.querySelector('.cards-container .card');
            let moveWidth = (cardElement.offsetWidth + 7);
            let maxLeft = -1 * (this.cardsContainer.current.offsetWidth - moveWidth);

            if ((this.cardsContainer.current.offsetLeft - moveWidth) >= maxLeft) {
                let left = (this.cardsContainer.current.offsetLeft - moveWidth);
                
                left = left <= maxLeft ? maxLeft : left;
                this.cardsContainer.current.style.left = left + 'px';

                this.setState({
                    left: true,
                    right: left > maxLeft
                });
            }
        }

        evt.stopPropagation();
    }
    render() {
        const { works, left, right } = this.state;
        return (
            <section className="works-so-far anchor-results" ref={this.sectionElem}>
                <div className="container">
                    <h1 className="page-title animate__animated">The results so far.</h1>
                    
                    <div className="cards-container-navigator animate__animated" ref={this.carouselNavElem}>
                        <Arrow direction="left"
                            active={left}
                            onClick={this.onClickPrev.bind(this)}></Arrow>
                        <Arrow direction="right"
                            active={right}
                            onClick={this.onClickNext.bind(this)}></Arrow>
                    </div>

                    <div className="cards-container-wrapper" ref={this.cardsContainerWrapper}>
                        <div className="cards-container scrollable-x" ref={this.cardsContainer}>
                            {works.map((w, i) => 
                                <Card key={i} index={i} content={w}></Card>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default WorksSoFar;