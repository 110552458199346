import React from 'react';
import './referee-person.scss';

const RefereePerson = (props) => {
    const { index } = props;
    const { name, company, position, email } = props.content;
    const initial = name.split(' ').reduce((acc, curr) => {
        return acc + curr[0].toUpperCase();
    }, '');
    return (
        <div className="referee-person animate__animated" style={{"--index":index}}>
            <div className="initials">{initial}</div>
            <div className="overlay">
                <div className="top">
                    <h4 className="name">{name}</h4>
                    <p className="sub company">{company}</p>
                    <p className="sub position">{position}</p>
                </div>
                <div className="footer">
                    <a className="sub email" href={`mailto:${email}`} target="_blank">{email}</a>
                </div>
            </div>
        </div>
    );
}

export default RefereePerson;