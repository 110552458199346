import React, { Component } from 'react';
import './App.scss';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './components/header';
import Home from './pages/home';
import Bio from './pages/bio';
import WorksDetail from './pages/works';
import PasswordProtect from './components/passwordProtect';
import ParallexGallery from './components/parallex-gallery';

class App extends Component{
  constructor(props) {
    super(props);
    this.state = {
      inverse: false,
      theatre: false,
      locked: true
    }
    this.toggleInverse = this.toggleInverse.bind(this);
    this.toggleTheatre = this.toggleTheatre.bind(this);
    this.prevHash = '';
  }
  toggleInverse(flag) {
    this.setState((state) => {
      return {
        ...state,
        inverse: flag
      }
    });
  }
  toggleTheatre(flag) {
    this.setState((state) => {
      return {
        ...state,
        theatre: flag
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.prevHash !== window.location.hash) {
      if (window.location.hash.split('/')[1] === 'works') {
        this.setState({
          inverse: true
        });
      }
      this.prevHash = window.location.hash;
    }
  }
  handlePasswordProtect(valid) {
    this.setState({
      locked: !valid
    });
  }
  render() {
    const { inverse, theatre, locked } = this.state;
    return (
      <>
      {locked && <PasswordProtect onValid={this.handlePasswordProtect.bind(this)}></PasswordProtect>}
      {!locked &&
      <Router hashType="hashbang">
          <div className={`App`}>
            <Header 
              inverse={inverse} 
              theatre={theatre}></Header>
            <Switch>
              <Route path="/(|home|results|work|news|references)">
                <Home 
                  onTheatreToggle={this.toggleTheatre} 
                  onToggleDark={this.toggleInverse}></Home>
              </Route>
              <Route path="/works/:id">
                <WorksDetail onToggleDark={this.toggleInverse}></WorksDetail>
              </Route>
              <Route path="/gallery/:id">
                <ParallexGallery />
              </Route>
              <Route path="/bio">
                <Bio onToggleDark={this.toggleInverse}></Bio>
              </Route>
            </Switch>
          </div>
      </Router>
      }
      </>
    );
  }
}


export default App;
