import React from 'react';
import './bio-highlight.scss';

const BioHighlight = (props) => {
    const { banner, companyLogo, position, period, description, 
        quote, achievements, brands, isCurrent,
        companyName, quoteByOthers, quoteBy, quoteBeforeBrands } = props.content;

    return (
        <div className="bio-highlight">
            
            {typeof banner !== 'object' &&
            <img className="banner" src={banner} />
            }
            {typeof banner === 'object' &&
            <div className="banner-with-text">
                <img src={banner.img} />
                <h3 dangerouslySetInnerHTML={{__html:banner.body}}></h3>
            </div>
            }
        
            <div className="container">
                <img className="logo" src={companyLogo} />
                {companyName != '' &&
                
                <h2 className="company-name">{companyName}</h2>
                }
                {(position != '' && position !== undefined) && 
                <h2 className="position">{position}</h2>
                }
                <h4 className="period">{period}</h4>

                <div className="description" dangerouslySetInnerHTML={{__html :description}}></div>

                {quoteByOthers != '' && 
                <blockquote>{quoteByOthers}</blockquote>
                }
                {quoteBy != '' &&
                <p className="quote-by">{quoteBy}</p>
                }
            </div>
            
            {quote != '' &&
            <div className="container">
                <blockquote className="quote">{quote}</blockquote>
            </div>
            }
            

            {achievements.length > 0 &&
            <div className="container">
                <div className="achievements">
                    {achievements.map((a, i) =>
                        <div className={`achievement ${a.items.length % 4 == 0 ? 'col-4':'col-3'}`} key={i}>
                            {a.title && <h4>{a.title}</h4>}
                            <div className="items">
                            {a.items.map((a,k) => 
                                <div className="item" key={k}>
                                    {a.title && <p className="sub title">{a.title}</p>}
                                    <p className="sub">{a.body}</p>
                                </div>
                            )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            }

            {quoteBeforeBrands !== '' &&
            <div className="container">
                <blockquote className="quote">{quoteBeforeBrands}</blockquote>
            </div>
            }
            <div className="container">
                {!Array.isArray(brands) && brands !== '' && <img className="brands" src={brands}/>}

                {Array.isArray(brands) && brands.length > 0 && 
                <div className="brands">
                    {brands.map((b, i) =>
                        <img key={i} src={`/img/brands/${b}.png`} />
                    )}
                </div>
                }
            </div>

        </div>
    );
}

export default BioHighlight;