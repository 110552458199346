import React, { Component } from 'react';
import Loader from '../loader';
import './video-player.scss';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.videoElem = React.createRef();
        this.state = {
            loading: false,
            playing: false,
            started: false,
            stopByUser: false
        }
        this.pausePlayVideo = this.pausePlayVideo.bind(this);
        this.windowScroll = this.windowScroll.bind(this);
        this.windowBlur = this.windowBlur.bind(this);
        this.windowFocus = this.windowFocus.bind(this);

        this.videoPlayerElem = React.createRef();
    }
    windowScroll() {
        let bbox = this.videoElem.current.getBoundingClientRect();
        if (bbox.top < 0 && bbox.bottom < 0 ||
            bbox.top > window.innerHeight && bbox.bottom > window.innerHeight) {
                this.videoElem.current.pause();
                this.setState({
                    playing: false,
                    started: false
                });
            }
    }
    windowBlur() {
        this.videoElem.current.pause();
        this.setState({
            playing: false
        })
    }
    windowFocus() {
        if (this.state.started && !this.state.stopByUser) {
            this.videoElem.current.play();
            this.setState({
                playing: true
            });
        }
    }
    componentDidMount() {
        this.videoElem.current.oncanplaythrough = () => {
            this.setState({
                loading: false
            });
        }
        this.videoElem.current.onended = () => {
            this.setState({
                playing: false,
                started: false
            });
        }
        this.videoElem.current.onpause = () => {
        }
        this.videoElem.current.onplay = () => {
            
        }
        
        window.addEventListener('blur', this.windowBlur);
        window.addEventListener('focus', this.windowFocus);

        // if out of view port - pause the video
        window.addEventListener('scroll', this.windowScroll);
        
        this.videoElem.current.src = this.props.video.url;
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.windowScroll);
        window.removeEventListener('blur', this.windowBlur);
        window.removeEventListener('focus', this.windowFocus);
    }
    
    pausePlayVideo() {
        const { playing, loading } = this.state;
        if (!playing && !loading) {
            this.videoElem.current.play();
            console.log(this.videoPlayerElem.current.getBoundingClientRect().top + window.scrollY)
            window.scroll({
                top: this.videoPlayerElem.current.getBoundingClientRect().top + window.scrollY,
                behavior: "auto"
            });
            this.setState({
                started: true,
                playing: true,
                stopByUser: false
            });
            //window.scroll({top: this.videoPlayerElem.current.getBoundingClientRect().top + window.scrollY });
        } else {
            this.videoElem.current.pause();
            this.setState({
                playing: false,
                stopByUser: true
            });
        }
    }
    render() {
        const { video: { poster } } = this.props;
        const { loading, playing, started } = this.state;
        return (
            <div className={`video-player ${!loading?'ready':''} ${playing?'playing':''} ${started?'started':''}`} ref={this.videoPlayerElem}>
                <div className="loader-container">
                    <Loader active={loading}></Loader>
                </div>
                <div className="controls">
                    <button className="btn-pause-play" onClick={this.pausePlayVideo.bind(this)}>
                        <span className="icon-pause"></span>
                        <span className="icon-play"></span>
                    </button>
                </div>
                <video ref={this.videoElem} poster={poster}>
                    <source src="" type="video/mp4" />
                </video>
            </div>
        );
    }
}

export default VideoPlayer;