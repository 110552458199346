import React from 'react';
import './card.scss';
import { NavLink  } from 'react-router-dom';

const Card = (props) => {
    const { img, title, shortExcerpt, url } = props.content;
    const { index } = props;
    return (
        <div className="card animate__animated" style={{"--index":index}}>
            <div className="body">
                {(url !== undefined && url != '') && 
                <NavLink to={url || ''}>
                    <div className="image">
                        <img src={img} alt={img}/>
                    </div>
                    <h4>{title}</h4>
                    <p className="sub" dangerouslySetInnerHTML={{__html: shortExcerpt}}></p>
                </NavLink>
                }
                {(url == undefined || url == '') && 
                <>
                    <div className="image">
                        <img src={img} alt={img}/>
                    </div>
                    <h4>{title}</h4>
                    <p className="sub" dangerouslySetInnerHTML={{__html: shortExcerpt}}></p>
                </>
                }
            </div>
            {url && url !=='' && 
            <div className="footer">
                <NavLink className="sub" to={url}>VIEW MORE</NavLink>
            </div>
            }
        </div>
    );
}

export default Card;