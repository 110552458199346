import React from 'react';
import { assetFolder } from './config';

const PhotosWrap = ({folder, images}) => {
  return (
    <div className="photos-wrap">
      {images.map((item) => (
        <div className="item">
          {Array.isArray(item) && item.map((subItem) => (
            <div className="item">
              <img src={`${assetFolder}${folder}/${subItem}`} alt="" />
            </div>
          ))}
          {!Array.isArray(item) && <img src={`${assetFolder}${folder}/${item}`} alt="" />}
        </div>
      ))}
    </div>
  );
}

export default PhotosWrap;