import React, { Component } from 'react';
import './section-scrollable.scss';

class SectionScrollable extends Component {
    constructor(props) {
        super(props);

        this.section = React.createRef();
        this.scrollY = 0;
    }
    componentDidMount() {
        var self = this;
        const scrollVertical = (deltaY, evt) => {
            self.maxScroll = self.section.current.querySelector('.scrollable').offsetHeight - self.section.current.querySelector('.container').offsetHeight;
            
            if ((self.scrollY == self.maxScroll && deltaY > 0)
                || (self.scrollY == 0 && deltaY < 0)) {
                return true;
            } else {
                let scrollY = self.scrollY + deltaY;
            
                if (scrollY > self.maxScroll) {
                    scrollY = self.maxScroll;
                }
                if (scrollY < 0) {
                    scrollY = 0;
                }
    
                if (scrollY >= 0 && scrollY <= self.maxScroll) {
                    
                    self.scrollY = scrollY;
                    self.section.current.querySelector('.scrollable').style.top = `${(-1 * self.scrollY)}px`
    
                    if (self.maxScroll - Math.floor(self.scrollY) <= 80) {
                        self.props.onScrollEnd && self.props.onScrollEnd();
                    } else {
                        self.props.onScrollNotEnd && self.props.onScrollNotEnd();
                    }
    
                    
                }
                evt.stopPropagation();
            }
        }

        this.section.current.addEventListener('mousewheel', (evt) => {
            let deltaY = evt.deltaY;            
            scrollVertical(deltaY, evt);
        });
        this.section.current.addEventListener('touchstart', (evt) => {
            this.touchStartY = evt.touches[0].pageY;
            this.touchStartYInitial = evt.touches[0].pageY;
        });
        this.section.current.addEventListener('touchmove', (evt) => {
            let delta = evt.changedTouches[0].pageY - this.touchStartY;
            this.touchStartY = evt.changedTouches[0].pageY;
            scrollVertical(-1 * delta, evt);
        });
        this.section.current.addEventListener('touchend', (evt) => {
            let delta = evt.changedTouches[0].pageY - this.touchStartY;
            let deltaFromTouch = evt.changedTouches[0].pageY - this.touchStartYInitial;
            
            if ((-1 * deltaFromTouch > 0 && self.scrollY == self.maxScroll)
                || (-1 * deltaFromTouch < 0 && self.scrollY == 0)) {
                return true;
            } else
                scrollVertical(-1 * delta, evt);
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active) {
            if (this.props.active) {
                this.scrollY = 0;
                this.section.current.querySelector('.scrollable').style.top = '0px';

                if (this.section.current.querySelector('.scrollable').scrollHeight > window.innerHeight)
                    this.props.hideNext();
                else
                    this.props.showNext();
            }
        }
    }
    render() {
        const { className } = this.props;
        return (
            <section className={`${className}`} ref={this.section}>
                <div className="container">
                    <div className="scrollable" style={{top:'0px'}}>
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionScrollable;