import React, { Component } from 'react';
import './works.scss';
import axios from 'axios';
import WorksGrid from '../../components/worksGrid';
import BackToTop from '../../components/backToTop';
import { withRouter } from 'react-router';
import VideoPlayer from '../../components/videoPlayer';

class WorksDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            work: {}
        }
        this.pageOuterElem = React.createRef();
        this.checkElementsInView = this.checkElementsInView.bind(this);
        this.loadContent = this.loadContent.bind(this);
        this.isDropboxMP4 = this.isDropboxMP4.bind(this);
    }
    checkElementsInView() {
        let buff = 80;
        let inViewAppearElements = document.querySelectorAll('.inview-track');
        let lower = window.innerHeight - buff;
        for(let elem of inViewAppearElements) {
            let top = elem.getBoundingClientRect().top;
            let bottom = elem.getBoundingClientRect().bottom;
            if ((top >= 0 && top <= lower)
                || (bottom >= 0 && bottom <= lower)
                || (top <= 0 && bottom >= window.innerHeight)) {
                    elem.classList.add('inview');
            }
        }
    }
    loadContent() {
        let self = this;
        const getData = () => {
            axios.get('/data/works/' + params.id + '.json')
            .then((response) => {
                self.setState({
                    work: response.data
                });
                self.pageOuterElem.current.classList.remove('loading');
            
            })
            .catch((error) => {

            })
            .then(() => {

            });
        }

        if (this.state.work.title !== undefined) {
            this.setState({
                work: {}
            });
        }

        const { match: { params } } = this.props;
        this.pageOuterElem.current.classList.add('loading');
        getData();
    }
    componentDidMount() {
        let body = document.querySelector('body');
        body.onscroll = this.checkElementsInView;
        this.loadContent();
        this.props.onToggleDark(true);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.work.title !== this.state.work.title && prevState.work.title === undefined) {
            this.checkElementsInView();
        }
        const { match: { params } } = this.props;

        if (params.id !== prevProps.match.params.id) {
            this.loadContent();
            document.querySelector('html').scroll({top:0});
        }
    }
    isDropboxMP4(v) {
        return (v.type !== undefined && v.type == 'video/mp4');
    }
    render() {
        const { work } = this.state;
        const { clientLogo, clientName, projectName, projectEyebrow, banner, title, body, quote, resultTitle, resultStatements, images } = work;
        return (
            <div className="bg-black page-outer works-detail" ref={this.pageOuterElem}>
                <a className="top"></a>
                <div className="page auto-height">
                    <section className="works-detail">
                        {work.title !== undefined && 
                        <>
                        <div className="banner-container" style={{backgroundImage: `url(${banner})`}}>
                            <div className="caption animate__animated">
                                {clientLogo != '' && <img className="logo" alt="" src={clientLogo} />}
                                <div className="client">{clientName}</div>
                                <div className="project" dangerouslySetInnerHTML={{__html: projectName}}></div>
                                <div className="eye-brow">{projectEyebrow}</div>
                            </div>
                        </div>
                        <div className="container">
                            {title != '' && <h1 className="page-title inview-track animate__animated">{title}</h1>}
                            <div className="body-quote">
                                <div className="x1 body-copy inview-track animate__animated" dangerouslySetInnerHTML={{ __html: body }}></div>
                                {quote != '' && <blockquote className="regular-headline inview-track animate__animated">{quote}</blockquote>}
                            </div>
                            <hr className={`${(resultTitle == '' && resultStatements.length == 0) ? 'mb-5':''}`}/>
                            {(resultTitle != '' || resultStatements.length > 0) &&
                            <div className="results">
                                <h1 className="page-title inview-track animate__animated">Results</h1>
                                <div className={`regular-headline inview-track animate__animated  ${resultStatements.length == 0 ? 'full-width':''}`}
                                    dangerouslySetInnerHTML={{__html: resultTitle}} ></div>
                                <div className="statements">
                                    {resultStatements.map((r, i) => (
                                        <p className="sub inview-track animate__animated" style={{"--index": i}} 
                                            key={i} dangerouslySetInnerHTML={{__html: r}}></p>
                                    ))}
                                </div>
                            </div>
                            }
                        </div>
                        </>
                        }
                    </section>
                    {images !== undefined &&
                    <section className="gallery">
                        {images.map((img, i) => (
                            <div className="item" key={i}>
                            {Array.isArray(img) && 
                                <div className="row">
                                    {img.map((subimg, k) => (<div key={k}><img src={subimg}  className={`inview-track animate__animated col`} /></div> ))}
                                </div>
                            }
                            {(!Array.isArray(img) && !this.isDropboxMP4(img) && img.type != 'text') && 
                            <img src={img} key={i} alt={img} className="inview-track animate__animated" />
                            }
                            {(!Array.isArray(img) && this.isDropboxMP4(img)) && 
                            <div className="video" key={i}>
                                <VideoPlayer video={img}></VideoPlayer>
                            </div>
                            }
                            {(!Array.isArray(img) && img.type == 'text' && !this.isDropboxMP4(img)) && 
                            <div className="container">
                                <div className="regular-headline inview-track animate__animated fg-white">
                                    {img.text}
                                </div>
                            </div>
                            }
                            {(i < (images.length - 1)) && 
                            <hr/>
                            }
                            </div>
                        ))}
                    </section>
                    }
                    <WorksGrid></WorksGrid>
                    <BackToTop 
                        inverse="true"
                        onClick={() => { }}>
                    </BackToTop>
                </div>
            </div>
            
        );
    }
}

export default withRouter(WorksDetail);