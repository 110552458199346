import React, { Component } from 'react';
import './contact.scss';
import axios from 'axios';
import Impossible from '../impossible';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: []
        }
    }
    componentDidMount() {
        let self = this;
        axios.get('/data/contact.json')
        .then((response) => {
            this.setState({
                contacts: response.data
            });
        })
        .catch((error) => {
            // handle error
            console.log(error);
        })
        .then(() => {
          // always executed
        });
    }
    render() {
        const { contacts } = this.state;
        return (
            <div className="contact">
                <h1 className="page-title animate__animated">Ways to reach me.</h1>
                <div className="row">
                {contacts.map((c, i) => 
                    <div className="item animate__animated" style={{"--index":i}} key={i}>
                        {c.url !='' &&
                        <>
                            <a target="_blank" href={c.url} ><img src={`/svg/${c.icon}`} alt={c.text}/></a>
                            <a className="sub" target="_blank" href={c.url}>{c.text}</a>
                        </>
                        }
                        {c.url == '' &&
                        <>
                            <img src={`/svg/${c.icon}`} alt={c.text} />
                            <p className="sub">{c.text}</p>
                        </>
                        }
                    </div>
                    )
                }
                </div>
            </div>
        );
    }
}

export default Contact;